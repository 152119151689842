
import Link from '@mui/material/Link';
import Container from '@mui/system/Container';
import Box from '@mui/system/Box';
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as TaskSafeColored } from 'assets/hrms-logo.svg';
import { ReactComponent as MSSignIn } from "assets/ms-symbollockup_signin_light.svg";
import { Button, Divider, Paper, Typography } from '@mui/material';

export const API = process.env.REACT_APP_API_ENDPOINT;

export default function Login() {

    return (
        <div
            style={{
                height: "100%",
                backgroundColor: "white",
                backgroundImage: "repeating-radial-gradient( circle at 0 0, transparent 0, #f6f6f6 100px ), repeating-linear-gradient( #fafafa55, #fafafa )",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container maxWidth="sm">
                <Paper sx={{p: 5,display: "flex", justifyContent: "center", flexDirection: "column", justifyItems: "center", alignItems: "center"}} elevation={0}>
                    <Box sx={{display: "flex", justifyContent: "center", flexDirection: "column", justifyItems: "center", alignItems: "center"}}>                            
                        <TaskSafeColored
                            title=''
                            style={{ width: "100%", height: "100%" }}
                        />
                        <Typography color="primary" align="center" variant='h3'>
                            Portal
                        </Typography>                    
                    </Box>
                    <Box sx={{width: "100%", mt: 4}}>
                        <Divider/>
                    </Box>
                    <Button 
                        sx={{mt: 5}}
                        href={`${API}/auth/signin`}
                    >
                        <MSSignIn
                            title=''
                        />
                    </Button>
                    <Box sx={{ 
                            position: "absolute",
                            bottom: "5px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            textAlign: "center"    
                        }}>
                        <Link component={RouterLink} to={"/privacy-policy"} variant="body2">
                            Privacy Policy
                        </Link>
                    </Box>
                </Paper>
            </Container>
        </div>
    )
}


