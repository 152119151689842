import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Routes,
  Route,
  Navigate as NavigateDom,
  useNavigate,
} from "react-router-dom";
import Login from 'components/Login';
import PrivacyPolicy from 'components/PrivacyPolicy';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';


const theme = createTheme({

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        }
      },
      defaultProps: {
        disableRipple: true,  
      }
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: { shrink: true }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            background: "inherit",
            width: "10px"
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar": {
            border: "solid 1px transparent"
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 10px 10px rgb(150, 150, 150)",
            borderRadius: "10px",
            border: "solid 1px transparent"
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0a293b",
      // se: "#0072A1"
      // main: "#0a293b",
    },
    secondary: {
      main: "#0072A1"
    }
  },
  shape: {
    borderRadius: 8
  },

});
 
export default function App() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  useEffect(() => {
    // Check if dev or prod
    if (process.env.NODE_ENV === 'development') {
      setShow(true);
      return;
    }

    if (Cookies.get('connect.sid')) {
      navigate('/dashboard');
      window.location.reload();
    } else {
      setShow(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {show &&
      <ThemeProvider theme={theme}>
            <Routes>
              <Route path="/" element={<Login/>} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/*"
                element={<NavigateDom to="/" />}
              />
            </Routes>
      </ThemeProvider>
    }
    </>
  )
}